.body-no-scroll {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  z-index: 9999;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.modal.open {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.form-container {
  background-color: #fff;
  text-align: left;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px 20px 20px;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.form-header {
  color: black;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  width: 100%;
  background: #fff;
  padding: 20px 0;
  z-index: 50;
}
.form-header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form-header h2 {
  margin: 0;
}

.form-header button {
  color: black;
  border: none;
  background: none;
  font-size: 18px;
  cursor: pointer;
}

.form-content {
  color: black;
}

.form-group {
  margin-bottom: 15px;
}

.form-group.checkbox-group {
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  gap: 10px;
  background: var(--Colors-white-white, #fff);
}
.checkbox-subtitle {
  color: #6c757d;
  font-size: 12px;
  margin: 4px;
  font-weight: 600;
}

.custom-checkbox {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  width: 100%;
}
.custom-checkbox input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
input[type="checkbox"] {
  accent-color: #e36b37;
}

input,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  padding: 10px;
  background-color: #e36b37;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  background: #e36b37;
}

button:hover {
  background-color: #e36b37;
}

.submit-button {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width: 768px) {
  .form-container {
    width: 90%;
    max-width: 350px;
    padding: 10px;
  }
}
